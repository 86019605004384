// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("jquery")
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

document.addEventListener("turbolinks:load", () => {

  // Run on Homepage
  if ($('#homepage').length > 0) {

    // Define homepage content
    const header = document.querySelector('header')
    const headerImg = document.querySelector('header svg')
    const headerSpan = document.querySelector('header b')
    const headerImgColor = document.querySelectorAll('.st0')
    const headerImgPath = document.querySelectorAll('path')
    const headerImgRect = document.querySelectorAll('rect')
    const contentStart = document.querySelector('.contentStart')
    const landingText = document.querySelector('.landing div')
    const landing = document.querySelector('.landing')

    // Reload header style
    checkScrollHeader()

    // if the window's height is less than 700px

    // when scrolling check
    window.addEventListener('scroll', function() {
      checkScrollHeader()
    })

    function checkScrollHeader() {
      // if the window scroll is past the content starting section
      if (window.scrollY >= contentStart.offsetTop - 65) {

        // what it should look like over content
        header.style.backgroundColor = 'white'
        header.style.height = '45px'
        header.style.paddingTop = '14px'
        header.style.color = 'black'
        header.style.borderBottom = '1px dotted #ff682c'
        headerImg.style.height = '25px'
        headerImg.style.marginTop = '-5px'
        headerSpan.style.color = '#ff682c'

        // change colour of the .com
        for (x = 0; x < headerImgPath.length; x++) {
          headerImgPath[x].style.fill = 'black'
        }
        for (x = 0; x < headerImgRect.length; x++) {
          headerImgRect[x].style.fill = 'black'
        }
        for (x = 0; x < headerImgColor.length; x++) {
          headerImgColor[x].style.fill = '#FF682C'
        }

      } else {

        // what it should look like over intro slider
        header.style.backgroundColor = 'transparent'
        header.style.height = '65px'
        header.style.paddingTop = '24px'
        header.style.color = 'white'
        header.style.borderBottom = 'none'
        headerImg.style.height = '35px'
        headerImg.style.marginTop = '-9px'
        headerSpan.style.color = 'white'

        // change colour of the .com
        for (x = 0; x < headerImgColor.length; x++) {
          headerImgColor[x].style.fill = 'white'
        }
        for (x = 0; x < headerImgPath.length; x++) {
          headerImgPath[x].style.fill = 'white'
        }
        for (x = 0; x < headerImgRect.length; x++) {
          headerImgRect[x].style.fill = 'white'
        }
      }

    }

    // More info to service sections
    const more_one = document.querySelector('#service_one .more')
    const more_one_text = document.querySelector('#service_one .more t')
    const more_one_span = document.querySelector('#service_one .more span')
    var service_one_open = false

    more_one.addEventListener('mousedown', function() {
      if (service_one_open == false) {
        more_one_span.style.animation = 'flip 0.3s forwards'
        $('#service_one .show').slideToggle();
        service_one_open = true
        more_one_text.innerHTML = 'Less'
      } else {
        more_one_span.style.animation = 'flipBack 0.3s forwards'
        $('#service_one .show').slideToggle();
        service_one_open = false
        more_one_text.innerHTML = 'More'
      }
    })

    const more_two = document.querySelector('#service_two .more')
    const more_two_text = document.querySelector('#service_two .more t')
    const more_two_span = document.querySelector('#service_two .more span')
    var service_two_open = false

    more_two.addEventListener('mousedown', function() {
      if (service_two_open == false) {
        more_two_span.style.animation = 'flip 0.3s forwards'
        $('#service_two .show').slideToggle();
        service_two_open = true
        more_two_text.innerHTML = 'Less'
      } else {
        more_two_span.style.animation = 'flipBack 0.3s forwards'
        $('#service_two .show').slideToggle();
        service_two_open = false
        more_two_text.innerHTML = 'More'
      }
    })

    const more_three = document.querySelector('#service_three .more')
    const more_three_text = document.querySelector('#service_three .more t')
    const more_three_span = document.querySelector('#service_three .more span')
    var service_three_open = false

    more_three.addEventListener('mousedown', function() {
      if (service_three_open == false) {
        more_three_span.style.animation = 'flip 0.3s forwards'
        $('#service_three .show').slideToggle();
        service_three_open = true
        more_three_text.innerHTML = 'Less'
      } else {
        more_three_span.style.animation = 'flipBack 0.3s forwards'
        $('#service_three .show').slideToggle();
        service_three_open = false
        more_three_text.innerHTML = 'More'
      }
    })

    // Setting for own carousel running on page
    $(document).ready(function(){
      $('.owl-one').owlCarousel({
        loop:true,
        margin:50,
        nav:false,
        responsive:{
            0:{
                items:1
            },
            600:{
                items:1
            },
            1000:{
                items:1
            }
        }
      })
      $('.owl-two').owlCarousel({
        loop:true,
        margin:120,
        nav:false,
        responsive:{
            0:{
                items:2
            },
            800:{
                items:3
            },
            1000:{
                items:4
            }
        }
      })
    });

  }


  // To update the current time in the header
  updateTime()

  function updateTime() {
    var dateInfo = new Date();

    /* time */
    var hr, _min = (dateInfo.getMinutes() < 10) ? "0" + dateInfo.getMinutes() : dateInfo.getMinutes(), ampm = (dateInfo.getHours() >= 12) ? "PM" : "AM";

    // replace 0 with 12 at midnight, subtract 12 from hour if 13‐23
    if (dateInfo.getHours() == 0) {
       hr = 12;
    } else if (dateInfo.getHours() > 12) {
       hr = dateInfo.getHours() - 12;
    } else {
       hr = dateInfo.getHours();
    }

    var currentTime = hr + ":" + _min + ampm.toLowerCase();

    // print time
    document.getElementById("currentTime").innerHTML = currentTime;

    // update every
    var time = setTimeout(updateTime,5000);
  }

  // Run on Blogs Page
  if ($('#blogspage').length > 0) {

    // Define filter variables
    var filterClassAll = document.querySelectorAll('.filters a')
    var blogPosts = document.querySelectorAll('.blogPost')
    var allArticles = document.getElementById('allArticles')

    // For each filter label add a unique click event listener
    for (x = 0; x < filterClassAll.length; x++) {
      filterClassAll[x].addEventListener("mousedown", bindClick(x));
    }

    // When an event listener is called, reset the blog post show and find the class that is the one that was clicked and show that one
    function bindClick(x) {
      return function() {
        resetFilters()
        for (y = 0; y < blogPosts.length; y++) {
          if ($(blogPosts[y]).hasClass(filterClassAll[x].classList[0])) {
            $(blogPosts[y]).removeClass('hide')
            $(blogPosts[y]).addClass('show')
            $(filterClassAll[x]).addClass('bold')
          }
        }
      }
    }

    // Reset the blog post show by looping through all and hiding them
    function resetFilters() {
      for (y = 0; y < filterClassAll.length; y++) {
        $(filterClassAll[y]).removeClass('bold')
        $(filterClassAll[y]).css("color", "black")
        $(allArticles).removeClass('bold')
        $(blogPosts[y]).removeClass('show')
        $(blogPosts[y]).addClass('hide')
      }
    }

    // If all articles is clicked, loop through and show all blog posts
    allArticles.addEventListener("mousedown", function() {
      for (y = 0; y < filterClassAll.length; y++) {
        $(allArticles).addClass('bold')
        $(blogPosts[y]).removeClass('hide')
        $(blogPosts[y]).addClass('show')
      }
    });

    // Search bar
    const searchInput = document.forms['searchInput'].querySelector('input');
    const blogTitles = document.querySelectorAll('.blogPost h1');
    const blogPost = document.querySelectorAll('.blogPost')

    // Listen for keyup on the search form input
    searchInput.addEventListener('keyup', function(e){

      // Force input to lowercase and assign to variable
    	const term = e.target.value.toLowerCase();

      // Loop through every blog title and check for text similar
    	for (i = 0; i < blogTitles.length; i++) {
    		var title = blogTitles[i].textContent
    		if(title.toLowerCase().indexOf(term) != -1) {
    			blogPost[i].style.display = 'block'
    		}
    		else {
    			blogPost[i].style.display = 'none'
    		}
    	}
    })

  }


  // Mobile Menu Open
  mobileMenuIcon = document.querySelector('.mobileMenuIcon')
  mobileMenuClose = document.querySelector('.mobileMenuClose')
  mobileMenu = document.querySelector('.mobileMenu')
  websiteWrapper = document.querySelector('wrapper')
  header = document.querySelector('header')

  mobileMenuIcon.addEventListener('click', function() {
    websiteWrapper.style.position = 'absolute'
    mobileMenu.style.transform = 'translateX(-90vw)'
    websiteWrapper.style.transform = 'translateX(-90vw)'
    mobileMenuIcon.style.opacity = '0'
    mobileMenuClose.style.opacity = '1'
    setTimeout(function(){
      header.style.transform = 'translateY(-65px)'
    }, 400);
  })

  mobileMenuClose.addEventListener('click', function() {
    mobileMenu.style.transform = 'translateX(0vw)'
    websiteWrapper.style.transform = 'translateX(0vw)'
    mobileMenuClose.style.opacity = '0'
    mobileMenuIcon.style.opacity = '1'
    setTimeout(function(){
      websiteWrapper.style.position = 'inherit'
      header.style.transform = 'translateY(0px)'
    }, 400);
  })


})
